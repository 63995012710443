import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks"

export default class extends Controller {
  static targets = ["netTotal", "vatTotal", "total", "withholdingTotal", "payableTotal", "paymentDetails",
    "myDataInvoice", "totalDeductions", "withholdTaxCode", "informationalWithholdingTotal",
    "stampDutyTaxCode", "totalDeductionsLabel", "withholdOnAmount", "account", "discountTotal",
    "totalStampDutyTaxField", "totalStampDutyTaxLabel", "b2g", "b2gDetails", "cpvCode", "code",
    "b2gCity", "b2gStreet", "b2gPostalCode", "b2gAahtName", "selfPricing", "selfPricingSelect",
    "deliveryNoteCheckbox", "deliveryNoteSection", "deliveryNoteMandatory", "fromAddress", "fromNumber",
    "fromCity", "fromZipcode", "tipAmount",
    "currency", "xChangeRate", "xChangeContainer", "selectedCurrency", "xChangeTotal", "xChangeRateSection",
    "b2gStreetNumber"]

  static values = {
    vatRate: Number, withholdTaxRate: Number, vatIncluded: Boolean, netTotal: Number, vatTotal: Number,
    total: Number, withholdingTotal: Number, withholdingInformationalIncluded: Boolean,
    withholdingInformationalTotal: Number, payableTotal: Number, totalDeductions: Number, showLoading: Boolean,
    discountValue: Number, errorsPresent: Boolean, stampDutyTaxAmountTotal: Number, minPayable: Number,
    b2g: Boolean, selfPricing: Boolean
  }

  initialize() {
    this.invoiceType = document.getElementById("invoice_invoice_type").value
    if (!this.element.classList.contains('accomondation_tax_invoice')) this.updateVariables()
    this.showLoadingValue = false
    this.disableEnterSubmit()
  }

  connect() {
    this.toggleSelfPricing()
    this.toggleDeliveryNoteForm()
    if (!this.element.classList.contains('accomondation_tax_invoice')) {
      document.getElementById("nested-rows").addEventListener("cocoon:after-remove", () => {
        this.updateVariables()
        this.displayDifferentDeductions()
      })
      document.getElementById("nested-rows").addEventListener("cocoon:after-insert", (e) => {
        const newLine = e.detail[2]
        this.defaultWithholdTaxCode("", newLine)
        this.displayDifferentDeductions()
        this.handleB2gFields()
        this.handleSelfPricingSelects()
        this.showDeliveryNoteMandatoryFields(this.hasDeliveryNoteCheckboxTarget &&
          this.deliveryNoteCheckboxTarget.checked)
      })
      this.updateWithholdCode()
      this.displayDifferentDeductions()
      this.loadSelectedAccountAddresses()
    }
  }

  loadSelectedAccountAddresses() {
    const recipientEl = document.getElementById("invoice_delivery_detail_attributes_recipient_of_movement")
    const addressEl = document.getElementById("invoice_delivery_detail_attributes_to_address")
    const showAutocomplete = (recipientEl || addressEl) && this.hasAccountTarget

    if (showAutocomplete) {
      const url = typeof this.accountTarget.selectedOptions === 'undefined' ?
        this.accountTarget.dataset.addressesUrl : this.accountTarget.selectedOptions[0].dataset.addressesUrl
      if (url) {
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            let html = ""
            data.map(address => {
              const thisAddress = [address.street, address.street_number, address.city, address.postal_code].join(" ")
              html += `<option value="${thisAddress}" data-address='${JSON.stringify(address)}' />`
            })
            document.getElementById("addresses").innerHTML = html
          })
      }
    }
  }

  disableEnterSubmit() {
    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 13 && e.target.type !== 'textarea') {
        e.preventDefault()
        return false
      }
    })
  }

  billingBookChanged(e) {
    this.setBillingBookData(e.target.value)
  }

  setBillingBookData(id) {
    const elem = document.querySelector(`.js-billing-book-data-${id}`)
    if (elem) {
      const data = JSON.parse(elem.dataset.value)
      this.bookSeriesTarget.value = data["series"]
      this.bookNumTarget.value = data["num"]
    }
  }

  updateVariables() {
    if (document.querySelector(".js-withhold-tax-rate")) {
      this.withholdTaxRateValue = this.parseIntOrZero(document.querySelector(".js-withhold-tax-rate").value)
    }
    this.vatIncludedValue = this.parseBoolOrFalse(document.querySelector(".js-vat-included").value)
    this.updatePrices()
  }

  calculateLineValues(unitPrice, quantity, vatRate, withholdTaxRate, vatIncluded = this.vatIncludedValue) {
    let subtotal, vatTotal, netTotalPrice, withholdingTotal
    if (vatIncluded) {
      subtotal = Number((unitPrice * quantity).toFixed(2))
      netTotalPrice = Number((subtotal / (1 + (vatRate / 100))).toFixed(2))
      vatTotal = Number((subtotal - netTotalPrice).toFixed(2))
    } else {
      netTotalPrice = Number((unitPrice * quantity).toFixed(2))
      vatTotal = Number((netTotalPrice * (vatRate / 100)).toFixed(2))
      subtotal = Number((vatTotal + netTotalPrice).toFixed(2))
    }
    withholdingTotal = Number((netTotalPrice * withholdTaxRate / 100).toFixed(2))

    return [subtotal, vatTotal, netTotalPrice, withholdingTotal]
  }

  checkFieldValue(e) {
    if (!e.target.value) {
      e.target.value = 1.00
    }
    this.updatePrices()
  }

  updatePrices() {
    this.resetTotals()
    document.querySelectorAll(".js-line").forEach(line => {
      if (line.style.display === "none") return // Reject nested fields set to destroy in edit form
      let unitPriceVal = this.limitDecimals(line.querySelector(".js-line-unit-price"))
      let quantityVal = this.limitDecimals(line.querySelector(".js-line-quantity"))
      const lineWithholdTaxCode = line.querySelector("[id*=withhold_tax_code]")
      const lineVatRate = line.querySelector(".js-line-vat-rate").value
      if (!unitPriceVal || !quantityVal) return

      let taxRate
      if (this.hasWithholdTaxCodeTarget) {
        taxRate = this.withholdTaxRateValue
      } else {
        let el = line.querySelector("[id*=withhold_tax_rate]")
        if (el === null) {
          taxRate = 0
        } else {
          taxRate = el.value
        }
      }

      const discountType = line.querySelector(".js-discount-type").value
      const discountAmount = Number(line.querySelector(".js-discount-amount").value)
      const discountPercentage = Number(line.querySelector(".js-discount-percentage").value)

      if (discountType == "amount" && discountAmount > 0) {
        unitPriceVal -= discountAmount
        this.discountValue += (discountAmount * quantityVal)
      } else if (discountType == "percentage" && discountPercentage > 0) {
        const thisDiscount = unitPriceVal * (discountPercentage / 100.0)
        unitPriceVal -= thisDiscount
        this.discountValue += (thisDiscount * quantityVal)
      }

      let [subtotal, vatTotal, netTotalPrice, withholdingTotal] = this.calculateLineValues(
        Number(unitPriceVal),
        Number(quantityVal),
        Number(lineVatRate),
        Number(taxRate)
      )


      line.querySelector(".js-line-net-price").value = netTotalPrice
      line.querySelector(".js-line-vat-total").value = vatTotal
      line.querySelector(".js-line-subtotal").value = subtotal
      line.querySelector(".invoice-line-item-subtotal").value = subtotal
      line.querySelector(".invoice-line-item-quantity").value = quantityVal
      line.querySelector(".js-line-withholding-total").value = withholdingTotal

      this.vatTotalValue = (this.vatTotalValue + vatTotal).toFixed(2)
      this.netTotalValue = (this.netTotalValue + netTotalPrice).toFixed(2)
      this.totalValue = Number((this.totalValue + subtotal).toFixed(2))

      // Handle Informational Withhold tax code
      if (this.hasWithholdTaxCodeTarget && ['8', '9', '10'].includes(this.withholdTaxCodeTarget.value) ||
          lineWithholdTaxCode && ['8', '9', '10'].includes(lineWithholdTaxCode.value)) {
        this.withholdingInformationalIncluded = true
        this.withholdingInformationalTotalValue = (this.withholdingInformationalTotalValue + withholdingTotal).toFixed(2)
      }

      // Handle Withhold tax code
      if (this.hasWithholdTaxCodeTarget && !['8', '9', '10'].includes(this.withholdTaxCodeTarget.value) ||
          lineWithholdTaxCode && !['8', '9', '10'].includes(lineWithholdTaxCode.value)) {
        this.withholdingTotalValue = (this.withholdingTotalValue + withholdingTotal).toFixed(2)
      }

      if (this.hasStampDutyTaxCodeTarget) {
        let stamp_duty = line.querySelector('.js-line-stamp-duty-tax-code').selectedOptions[0]
        let percentage = parseFloat(stamp_duty.dataset.stampDutyTaxValue)
        if (!!percentage) {
          this.stampDutyTaxAmountTotalValue += Number(((subtotal * percentage) / 100).toFixed(2))
        } else if (stamp_duty.value == "4") {
          const stampDutyAmount = Number(line.querySelector("input[name*=stamp_duty_amount]").value).toFixed(2)
          this.stampDutyTaxAmountTotalValue += stampDutyAmount
        }
        this.totalStampDutyTaxFieldTarget.value = this.stampDutyTaxAmountTotalValue.toFixed(2)
      }
      if (this.stampDutyTaxAmountTotalValue) {
        this.totalStampDutyTaxLabelTarget.parentElement.hidden = false
      } else {
        this.totalStampDutyTaxLabelTarget.parentElement.hidden = true
      }
    })

    if (this.hasWithholdOnAmountTarget && this.withholdOnAmountTarget.value > 0) {
      if (this.hasWithholdTaxCodeTarget && ['8', '9', '10'].includes(this.withholdTaxCodeTarget.value)) {
        this.withholdingTotalValue = 0
        this.withholdingInformationalIncluded = true
        this.withholdingInformationalTotalValue = (this.withholdOnAmountTarget.value * this.withholdTaxRateValue / 100).toFixed(2)
      } else {
        this.withholdingTotalValue = (this.withholdOnAmountTarget.value * this.withholdTaxRateValue / 100).toFixed(2)
      }
    }

    this.updateDeductions()
    this.payableTotalValue = Number(this.totalValue + this.stampDutyTaxAmountTotalValue - (this.withholdingTotalValue + this.totalDeductionsValue) + Number(this.tipAmountTarget.value)).toFixed(2)
    this.updateExchangeRate()
    this.updateTotals()
  }

  updateExchangeRate() {
    if (this.hasCurrencyTarget && this.currencyTarget.value && this.currencyTarget.value == 'EUR') {
      this.xChangeContainerTarget.hidden = true
      this.xChangeRateSectionTarget.hidden = true
      this.xChangeRateTarget.classList.remove("border", "border-2", "border-danger", "rounded")
    } else {
      if (this.hasXChangeRateSectionTarget) {
        this.xChangeRateSectionTarget.hidden = false
      }
    }
    if (this.hasCurrencyTarget && this.currencyTarget.value != 'EUR') {
      var xchangeText = document.getElementById('invoice_exchange_rate')?.placeholder
      document.getElementById('exchangeRateTooltip')?.setAttribute('data-bs-original-title', `${xchangeText}: EUR/${this.currencyTarget.value}`);
      if (this.xChangeRateTarget.value && this.xChangeRateTarget.value >= 0 && this.currencyTarget.value && this.payableTotalValue > 0) {
        this.xChangeTotalTarget.innerHTML = (this.payableTotalValue * this.xChangeRateTarget.value).toFixed(2)
        let options = Array.from(this.currencyTarget.options)
        this.selectedCurrencyTarget.innerHTML = options[options.map((e) => { return e['value'] }).indexOf(this.currencyTarget.value)].text
        this.xChangeContainerTarget.hidden = false
        this.xChangeRateTarget.classList.remove("border", "border-2", "border-danger", "rounded")
      } else if (!this.xChangeRateTarget.value) {
        this.xChangeTotalTarget.innerHTML = 0
      }
    }
  }

  updateDeductions() {
    this.totalDeductionsValue = 0
    let deductions = []
    document.querySelectorAll(".js-line").forEach(line => {
      let lineAmount = 0
      line.querySelectorAll(".deductions-wrapper .row").forEach(deduction => {
        let title = deduction.querySelector("[name*=title]").value
        if ( title == "" ) { title = "Κράτηση" }
        const amount = Number(deduction.querySelector("[name*=amount]").value)
        const informational = deduction.querySelector(".form-check-input").checked
        const deleted = deduction.querySelector("[name*=_destroy]").value
        if ( amount > 0 && !informational && deleted != "true" ) { lineAmount += amount }
        if ( amount > 0 && deleted != "true" ) { deductions.push({ title, amount, informational }) }
      })
      line.querySelectorAll(".js-deduction-amount").forEach(elem => {
        if ( elem.nodeName == "INPUT" ) {
          elem.value = lineAmount.toFixed(2)
        } else if ( elem.nodeName == "SPAN" ) {
          elem.textContent = lineAmount.toFixed(2)
        }
      })
      this.totalDeductionsValue += lineAmount
    })
    document.getElementById("invoice_total_deductions").value = this.totalDeductionsValue
    this.displayDifferentDeductions(deductions)
  }

  setStampDutyTaxAmount(line) {
    if (this.hasStampDutyTaxCodeTarget) {
      let stamp_duty = line.querySelector('.js-line-stamp-duty-tax-code').selectedOptions[0]
      let percentage = parseFloat(stamp_duty.dataset.stampDutyTaxValue)
      if (!!percentage) {
        this.totalStampDutyTaxLabelTarget.parentElement.hidden = false
        this.stampDutyTaxAmountTotalValue = ((this.totalValue * percentage) / 100).toFixed(2)
        this.totalStampDutyTaxFieldTarget.value = this.stampDutyTaxAmountTotalValue
        this.totalStampDutyTaxLabelTarget.innerText = this.stampDutyTaxCodeTarget.dataset.labelLocale + " (" + stamp_duty.dataset.stampDutyTaxPercentage + ")"
      } else {
        this.stampDutyTaxAmountTotalValue = 0
        this.totalStampDutyTaxFieldTarget.value = 0
      }
    }
  }

  updateTotals() {
    this.netTotalTarget.value = (this.discountValue == 0 ?
      this.netTotalValue.toFixed(2) : (this.netTotalValue + this.discountValue).toFixed(2))
    this.vatTotalTarget.value = this.vatTotalValue
    this.totalTarget.value = (this.totalValue + this.stampDutyTaxAmountTotalValue).toFixed(2)
    if (this.hasWithholdingTotalTarget) {
      this.withholdingTotalTarget.value = this.withholdingTotalValue
    }
    this.payableTotalTarget.value = this.payableTotalValue.toFixed(2)
    this.displayDifferentVats()
    this.displayInformationalWithholdTax()
    this.displayDifferentWithholdTaxes()
    this.showDiscount()
    this.handleSubmitBtn()
  }

  handleSubmitBtn() {
    const submitBtn = document.querySelector('[type="submit"][name="issue_and_send"]')
    const draftBtn = document.querySelector('[name="create_draft"]')
    submitBtn.disabled = !(this.netTotalTarget.value >= this.minPayableValue && this.totalTarget.value >= this.minPayableValue) || this.errorsPresentValue
    if (draftBtn) {
      draftBtn.disabled = !(this.netTotalTarget.value >= this.minPayableValue && this.totalTarget.value >= this.minPayableValue) || this.errorsPresentValue
    }
    let el = document.querySelector('#invoice_recipient_of_movement')
    if (el) {
      el.dispatchEvent(new Event('input'))
    }
  }

  showDiscount() {
    if (this.discountValue > 0) {
      this.discountTotalTarget.value = this.discountValue.toFixed(2)
      this.discountTotalTarget.closest(".row").hidden = false
    } else {
      this.discountTotalTarget.closest(".row").hidden = true
    }
  }

  checkWithholdValid() {
    const select = document.querySelector(".js-withhold-tax-rate")
    if (select && select.selectedOptions && select.selectedOptions[0].value === "20" && Number(this.netTotalTarget.value) <= 300) {
      select.selectedIndex = -1
      select.value = "0"
      this.updateVariables()
      setTimeout(() => { $('#withhold-tooltip').tooltip('show') }, 100)
      setTimeout(() => { $('#withhold-tooltip').tooltip('hide') }, 4000)
    }
  }

  resetTotals() {
    this.netTotalValue = 0
    this.vatTotalValue = 0
    this.totalValue = 0
    this.withholdingTotalValue = 0
    this.withholdingInformationalIncluded = false
    this.withholdingInformationalTotalValue = 0
    this.payableTotalValue = 0
    this.discountValue = 0
    this.stampDutyTaxAmountTotalValue = 0
    this.totalDeductionsValue = 0
  }

  parseIntOrZero(val) {
    if (val === "" || Number.isNaN(val)) return 0
    return parseInt(val)
  }

  parseBoolOrFalse(val) {
    return (val.toString() === "1")
  }

  clientCountry() {
    const elem = document.querySelector(".js-account-select")
    return elem ? elem.options[elem.selectedIndex].dataset.country : undefined
  }

  validateAccountSelection(element) {
    if (element.value === '') {
      element.classList.add('border', 'border-2', 'border-danger', 'rounded')
      document.getElementById('account_error').classList.remove('d-none')
    } else if (element.selectedOptions[0].dataset.vatAddressRequired === 'true') {
      element.classList.add('border', 'border-2', 'border-danger', 'rounded')
    } else {
      element.classList.remove('border', 'border-2', 'border-danger', 'rounded')
      document.getElementById('account_error').classList.add('d-none')
    }
  }

  requireVatAccountFullAddress() {
    let account_field = document.getElementById('invoice_account_id')
    let requireAddress = account_field.selectedOptions[0].dataset.vatAddressRequired === 'true'

    this.validateAccountSelection(account_field)

    if (requireAddress) {
      let show_modal_url = account_field.selectedOptions[0].dataset.showModalUrl

      Rails.ajax({
        url: show_modal_url,
        type: 'GET',
        dataType: "json",
        success: (response) => {
          Rails.$('#personalAccountForm input[name="address"]')[0].value = response['address']
          Rails.$('#personalAccountForm input[name="address"]')[0].disabled = !!response['address']
          Rails.$('#personalAccountForm input[name="city"]')[0].value = response['city']
          Rails.$('#personalAccountForm input[name="city"]')[0].disabled = !!response['city']
          Rails.$('#personalAccountForm input[name="postal_code"]')[0].value = response['postal_code']
          Rails.$('#personalAccountForm input[name="postal_code"]')[0].disabled = !!response['postal_code']
          Rails.$('#personalAccountForm input[name="personal_vat"]')[0].value = response['vat_no']
          Rails.$('#personalAccountForm input[name="personal_vat"]')[0].disabled = !!response['vat_no']
          Rails.$('#personalAccountForm')[0].dataset.updatePath = response['update_path']
          let accountFormElement = document.querySelector("#personalAccountForm")
          let accountFormModal = bootstrap.Modal.getOrCreateInstance(accountFormElement)
          accountFormModal.show()
        }
      })
    }

    return requireAddress
  }

  updateWithholdCode() {
    if (this.hasWithholdTaxCodeTarget) {
      const withholdRate = document.querySelector(".js-withhold-tax-rate")
      const selectedTaxCode = this.withholdTaxCodeTarget.selectedOptions[0]
      withholdRate.value = selectedTaxCode.dataset.value

      this.updateVariables()
      if (selectedTaxCode.dataset.value != "0") {
        if (['8', '9'].includes(selectedTaxCode.value)) {
          this.informationalWithholdingTotalTarget.closest("div").classList.remove("d-none")
          this.withholdingTotalTarget.closest("div").classList.add("d-none")
        } else {
          this.informationalWithholdingTotalTarget.closest("div").classList.add("d-none")
          this.withholdingTotalTarget.closest("div").classList.remove("d-none")
        }
        this.withholdOnAmountTarget.closest("div").classList.remove("d-none")
      } else {
        if (this.hasWithholdOnAmountTarget) {
          this.withholdOnAmountTarget.closest("div").classList.add("d-none")
        }
        if (this.informationalWithholdingTotalTarget) {
          this.informationalWithholdingTotalTarget.closest("div").classList.add("d-none")
        }
        document.getElementById('invoice_withholding_total').closest('div').classList.add('d-none')
      }
    }
  }

  showModal() {
    if (this.showLoadingValue) {
      const modal = new bootstrap.Modal(document.getElementById("sending"))
      modal.show()
    }
  }

  setShowLoading(e) {
    let account_field = document.getElementById('invoice_account_id')
    const vatExeption = this.vatExemptionValidation()

    if (account_field && this.requireVatAccountFullAddress()) {
      document.getElementById('account_field').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      e.preventDefault()
    } else if (this.hasCurrencyTarget.value && this.currencyTarget.value && this.currencyTarget.value != 'EUR' && (!this.xChangeRateTarget.value || this.xChangeRateTarget.value <= 0)) {
      this.xChangeRateTarget.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      this.xChangeRateTarget.classList.add("border", "border-2", "border-danger", "rounded")
      e.preventDefault()
    } else if (account_field && account_field.value && vatExeption.valid) {
      this.showLoadingValue = e.target.value != "draft"
    } else if (account_field && !account_field.value && !account_field.classList.contains('optional')) {
      this.validateAccountSelection(account_field)
      document.getElementById('account_field').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      e.preventDefault()
    } else if (!vatExeption.valid) {
      const firstElement = vatExeption.elements[0]
      firstElement.classList.add("border", "border-2", "border-danger", "rounded")
      firstElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      e.preventDefault()
    } else {
      this.showLoadingValue = e.target.value != "draft"
    }
  }

  displayInformationalWithholdTax() {
    if (this.hasWithholdTaxCodeTarget && ['8', '9'].includes(this.withholdTaxCodeTarget.value)) {
      this.informationalWithholdingTotalTarget.value = this.withholdingInformationalTotalValue
    }
  }

  displayDifferentVats() {
    if (!["title_deed", "title_deed_refusal", "delivery_note"].includes(this.invoiceType)) {
      const vatsWrapper = document.getElementById("vatsWrapper")
      vatsWrapper.innerHTML = ""
      let vats = {}
      let vatExemptions = []

      document.querySelectorAll(".js-line-vat-rate").forEach((v) => {
        if (v.value) {
          const existingTotal = parseFloat(vats[v.value]) || 0
          const vatTotal = parseFloat(v.closest(".js-line").querySelector(".js-line-vat-total").value) || 0
          vats[v.value] = (existingTotal + vatTotal).toFixed(2)
          if (v.selectedOptions[0].value == '0') {
            let vatExemption = v.closest('.js-line').querySelector('[data-invoice-line-target=vatExemption]').querySelector('select')
            vatExemptions.push(vatExemption.selectedOptions[0].innerText)
            vatExemptions = [...new Set(vatExemptions)] // Make the array with unique values
          }
        }
      })

      if (Object.keys(vats).length) {
        for (const [k, v] of Object.entries(vats)) {
          let thisTemplate = document.querySelector(".vat-template").cloneNode(true)
          vatsWrapper.append(thisTemplate)
          thisTemplate.classList.remove("vat-template")
          thisTemplate.innerHTML = thisTemplate.innerHTML.replace("KEY", k)
          thisTemplate.innerHTML = thisTemplate.innerHTML.replace("VALUE", v)
          if (k === '0') {
            thisTemplate.innerHTML = thisTemplate.innerHTML.replace("VATEXEMPTION", vatExemptions.join('<br>'))
            thisTemplate.querySelector('.vat-template-vat-exemption').hidden = false
          } else {
            thisTemplate.querySelector('.vat-template-vat-exemption').hidden = true
          }
          thisTemplate.hidden = false
        }
      }
    }

  }

  displayDifferentDeductions(deductions) {
    const deductionsWrapper = document.getElementById('deductionsWrapper')
    deductionsWrapper.innerHTML = ''
    if ( deductions ) {
      deductions.map(deduction => {
        let deductionTemplate = document.querySelector('.deduction-template').cloneNode(true)
        deductionTemplate.classList.remove('deduction-template')
        deductionTemplate.innerHTML = deductionTemplate.innerHTML.replace("DEDUCTION_TITLE", deduction.title)
        deductionTemplate.querySelector('input').value = deduction.amount
        if ( deduction.informational ) {
          const message = "Η παρακράτηση αυτή δεν επηρεάζει το πληρωτέο ποσό αλλά εμφανίζεται στο παραστατικό για πληροφοριακούς σκοπούς."
          this.appendTooltip(deductionTemplate.querySelector("label"), message)
        }
        deductionTemplate.hidden = false
        deductionsWrapper.append(deductionTemplate)
      })
    }
  }

  vatExemptionValidation() {
    let valid = true
    let elements = []

    document.querySelectorAll(".js-line").forEach((line) => {
      const vatRate = line.querySelector(".js-line-vat-rate").value
      const vatExemptionCode = line.querySelector("[id*='vat_exemption_code']")
      const vatExemptionCodeValue = vatExemptionCode.value

      if (vatRate == "0" && vatExemptionCodeValue == "") {
        valid = false
        elements.push(vatExemptionCode)
      }
    })
    return {
      valid: valid,
      elements: elements
    }
  }

  handleInvoiceTitle(e) {
    const title = e.target.options[e.target.selectedIndex].dataset.createTitle

    if (title) {
      document.querySelector("h1.page-heading").innerText = title
    }
  }

  setInvoiceLineWithholdTax(e) {
    const selectedValue = e.target.selectedOptions[0].dataset.value
    const line = e.target.closest(".js-line")
    const withholdTaxRate = line.querySelector("[id*=withhold_tax_rate]")
    withholdTaxRate.value = selectedValue
    this.updatePrices()
  }

  displayDifferentWithholdTaxes() {
    if (document.getElementById("withholdTaxesWrapper")) {
      const withholdTaxesWrapper = document.getElementById("withholdTaxesWrapper")
      withholdTaxesWrapper.innerHTML = ""
    }
    let withhold_taxes = {}

    // Display Different withhold Taxes when
    // total value is zero AND there are 15% for lawyers
    // total value is NOT zero

    if (!this.hasWithholdTaxCodeTarget &&
      (this.withholdingTotalValue > 0 ||
        (this.withholdingTotalValue === 0 && this.withholdingInformationalIncluded === true)
      )
    ) {

      // Display Invoice Withholding Total when value is not zero
      if (this.withholdingTotalValue > 0) {
        document.getElementById('invoice_withholding_total').closest('div').classList.remove('d-none')
      } else {
        document.getElementById('invoice_withholding_total').closest('div').classList.add('d-none')
      }

      document.querySelectorAll(".js-line").forEach(line => {
        if (line.querySelector("[id*=withhold_tax_code]").selectedOptions[0].value) {
          const total = Number(line.querySelector("[id*=withholding_total]").value)
          const text = line.querySelector("[id*=withhold_tax_code]").selectedOptions[0].text
          const code = line.querySelector("[id*=withhold_tax_code]").selectedOptions[0].value
          if (code in withhold_taxes) {
            withhold_taxes[code] = {
              text: text,
              total: withhold_taxes[code].total += total
            }
          } else {
            withhold_taxes[code] = { text: text, total: total}
          }
        }

      })

      if (Object.keys(withhold_taxes).length) {
        for (const [k, v] of Object.entries(withhold_taxes)) {
          let thisTemplate = document.querySelector(".vat-template").cloneNode(true)
          withholdTaxesWrapper.append(thisTemplate)
          thisTemplate.classList.remove("vat-template")
          thisTemplate.querySelector("label").innerText = v.text

          // When withholding tax code is 10 append tooltip icon after description label
          if (this.withholdingInformationalIncluded && ['8', '9', '10'].includes(k)) {
            this.appendTooltip(thisTemplate.querySelector("label"), "Η παρακράτηση αυτή δεν επηρεάζει το πληρωτέο ποσό αλλά εμφανίζεται στο παραστατικό για πληροφοριακούς σκοπούς.")
          }

          thisTemplate.innerHTML = thisTemplate.innerHTML.replace("VALUE", v.total)
          thisTemplate.hidden = false
        }

      }

    } else if (document.getElementById('invoice_withholding_total') &&
      !this.hasWithholdTaxCodeTarget && this.withholdingTotalValue === 0) {
      document.getElementById('invoice_withholding_total').closest('div').classList.add("d-none")
    }
  }

  appendTooltip(label_element, message = "") {
    const tooltip = document.createElement('div')
    tooltip.classList.add('d-inline-block', 'ps-3')
    const tooltip_icon = document.createElement('i')
    tooltip_icon.classList.add('fa-solid', 'fa-circle-info', 'pe-2', 'fs-5', 'text-primary', 'cursor-pointer')
    tooltip_icon.dataset.action = "click->invoice-form#informationalTooltipToggle"
    tooltip.appendChild(tooltip_icon)
    label_element.appendChild(tooltip)
    const tooltip_text = document.createElement('p')
    tooltip_text.classList.add('col', 'col-md-7', 'ms-auto', 'p-3', 'fs-8', 'd-none', 'tooltip-text', 'border-end', 'border-primary', 'me-4')
    tooltip_text.innerText = message
    label_element.appendChild(tooltip_text)
  }

  informationalTooltipToggle(e) {
    e.target.closest('label').querySelector('.tooltip-text').classList.toggle('d-none')
  }


  // When selected account country is Greece or no account selected then we set the default withhold tax
  // When selected country is not Greece then we deselect any withhold tax
  defaultWithholdTaxCode(e, line) {
    const invoiceType = document.getElementById("invoice_invoice_type").value
    if (!["retail_sales_receipt", "receipt", "rent_income", "simplified_invoice"].includes(invoiceType) &&
      !(new URLSearchParams(window.location.search).has("from_invoice"))) {
      const selectedAccount = document.querySelector(".js-account-select").selectedOptions[0]
      const defaultWithholdTaxCode = document.querySelector(".js-account-select").dataset.defaultWithholdTaxCode
      const currentCountry = selectedAccount.dataset.countryIso2

      if ((defaultWithholdTaxCode && !currentCountry) || (defaultWithholdTaxCode && currentCountry == "GR")) {
        if (line) {
          const lineWithholdTaxCode = line.querySelector("[id*=withhold_tax_code]")
          this.setFieldAndDispatchEventWithTimeout(lineWithholdTaxCode, defaultWithholdTaxCode, "change", 1)
        } else {
          document.querySelectorAll(".js-line").forEach(line => {
            const lineWithholdTaxCode = line.querySelector("[id*=withhold_tax_code]")
            if (lineWithholdTaxCode != null && lineWithholdTaxCode.value == "") {
              this.setFieldAndDispatchEventWithTimeout(lineWithholdTaxCode, defaultWithholdTaxCode, "change", 1)
            }
          })
        }
      } else {
        if (line) {
          const lineWithholdTaxCode = line.querySelector("[id*=withhold_tax_code]")
          this.setFieldAndDispatchEventWithTimeout(lineWithholdTaxCode, "", "change", 1)
        } else {
          document.querySelectorAll(".js-line").forEach(line => {
            const lineWithholdTaxCode = line.querySelector("[id*=withhold_tax_code]")
            this.setFieldAndDispatchEventWithTimeout(lineWithholdTaxCode, "", "change", 1)
          })
        }
      }
    }
  }

  limitDecimals(element) {
    if (!element) return
    let value = element.value
    let decimals = parseInt(element.dataset.decimals)
    if (value && !Number.isInteger(value * 1) && value.replace(",", ".").split(".")[1].length > decimals) {
      let newValue = (value * 1).toFixed(decimals)
      element.value = newValue
      return newValue
    } else {
      return element.value
    }
  }


  // appendTooltip: Appends a clickable info icon and a message inside the descriptional label element
  appendTooltip(label_element, message = "") {
    const tooltip = document.createElement('div')
    tooltip.classList.add('d-inline-block', 'ps-3')
    const tooltip_icon = document.createElement('i')
    tooltip_icon.classList.add('fa-solid', 'fa-circle-info', 'pe-2', 'fs-5', 'text-primary', 'cursor-pointer')
    tooltip_icon.dataset.action = "click->invoice-form#informationalTooltipToggle"
    tooltip.appendChild(tooltip_icon)
    label_element.appendChild(tooltip)
    const tooltip_text = document.createElement('p')
    tooltip_text.classList.add('col', 'col-md-7', 'ms-auto', 'p-3', 'fs-8', 'd-none', 'tooltip-text', 'border-end', 'border-primary', 'me-4')
    tooltip_text.innerText = message
    label_element.appendChild(tooltip_text)
  }

  informationalTooltipToggle(e) {
    e.target.closest('label').querySelector('.tooltip-text').classList.toggle('d-none')
  }

  setFieldAndDispatchEventWithTimeout(field, value, event, timeout) {
    if (field) {
      field.value = value
      setTimeout(() => { field.dispatchEvent(new Event(event)) }, timeout)
    }
  }

  checkLimit(e) {
    const alert = e.target.parentElement.nextElementSibling
    if (Number(e.target.value) > Number(e.target.dataset.limit)) {
      alert.hidden = false
      this.errorsPresentValue = true
    } else {
      alert.hidden = true
      this.errorsPresentValue = false
    }
  }

  setB2g() {
    this.b2gValue = this.b2gTarget.checked
  }

  handleB2gFields() {
    this.b2gDetailsTarget.hidden = !this.b2gValue
    this.cpvCodeTargets.map(cpvCode => {
      cpvCode.hidden = !this.b2gValue
    })
    this.codeTargets.map(code => {
      code.hidden = this.b2gValue
    })
  }

  b2gValueChanged() {
    this.handleB2gFields()
    this.updateB2gFields()
  }

  updateB2gFields() {
    if (this.b2gValue && this.accountTarget.value) {
      fetch(`/el/accounts/${this.accountTarget.value}/b2g_info`)
        .then(response => response.json())
        .then((data) => {
          if (this.b2gCityTarget.value == "") this.b2gCityTarget.value = data.city
          if (this.b2gStreetTarget.value == "") this.b2gStreetTarget.value = data.address
          if (this.b2gPostalCodeTarget.value == "") this.b2gPostalCodeTarget.value = data.postal_code
          if (this.b2gAahtNameTarget.value == "") this.b2gAahtNameTarget.value = data.aaht_name
          if (this.b2gStreetNumberTarget.value == "") this.b2gStreetNumberTarget.value = data.street_number
        })
    }
  }

  toggleSelfPricing() {
    if (this.hasSelfPricingTarget) {
      this.selfPricingValue = this.selfPricingTarget.checked
    }
    this.handleSelfPricingSelects()
  }

  handleSelfPricingSelects() {
    this.selfPricingSelectTargets.map(select => {
      select.hidden = !this.selfPricingValue
    })
  }

  toggleDeliveryNoteForm() {
    if (this.hasDeliveryNoteCheckboxTarget && this.deliveryNoteCheckboxTarget.checked) {
      this.deliveryNoteSectionTarget.classList.add("show")
      this.showDeliveryNoteMandatoryFields(true)
      this.autocompleteDeliveryFromAddress()
      this.handleDeliveryNoteDateAndTime("open")
    } else if (this.hasDeliveryNoteCheckboxTarget && !this.deliveryNoteCheckboxTarget.checked) {
      this.deliveryNoteSectionTarget.classList.remove("show")
      this.showDeliveryNoteMandatoryFields(false)
      this.handleDeliveryNoteDateAndTime("close")
    }
  }

  // This method added to keep datalist for autocomplete but split the address when needed
  // because datalist option element can't get data-action
  completeAddress(e) {
    const value = e.target.value
    const addresses = document.getElementById("addresses")
    if (e.target.id.includes("address") && addresses.childNodes.length > 0) {
      const options = addresses.childNodes
      options.forEach((option) => {
        if (option.value === value) {
          const addressObj = JSON.parse(option.dataset.address)
          document.getElementById("invoice_delivery_detail_attributes_to_address").value = addressObj.street
          document.getElementById("invoice_delivery_detail_attributes_to_number").value = addressObj.street_number
          document.getElementById("invoice_delivery_detail_attributes_to_city").value = addressObj.city
          document.getElementById("invoice_delivery_detail_attributes_to_zipcode").value = addressObj.postal_code
        }
      })
    }
  }

  // This function adds * to delivery note mandatory fields when show = true
  // show is boolean
  showDeliveryNoteMandatoryFields(show) {
    this.deliveryNoteMandatoryTargets.map(elem => {
      elem.textContent = elem.textContent.split("*")[0]
      if (show) {
        elem.textContent = elem.textContent + "*"
      } else {
        elem.textContent = elem.textContent.split("*")[0]
      }
    })
  }

  toggleDeliveryNote() {
    if (this.hasDeliveryNoteCheckboxTarget) {
      this.deliveryNoteCheckboxTarget.checked = !this.deliveryNoteCheckboxTarget.checked
      this.toggleDeliveryNoteForm()
    }
  }

  autocompleteDeliveryFromAddress() {
    if (this.fromAddressTarget.value == "" && this.fromNumberTarget.value == "" &&
      this.fromCityTarget.value == "" && this.fromZipcodeTarget.value == "") {
      this.fromAddressTarget.value = this.deliveryNoteCheckboxTarget.dataset.companyAddress
      this.fromNumberTarget.value = this.deliveryNoteCheckboxTarget.dataset.companyStreetNumber
      this.fromCityTarget.value = this.deliveryNoteCheckboxTarget.dataset.companyCity
      this.fromZipcodeTarget.value = this.deliveryNoteCheckboxTarget.dataset.companyZipcode
    }
  }

  // status can be "open" or "close"
  handleDeliveryNoteDateAndTime(status) {
    const deliveryDateField = document.getElementById("invoice_delivery_detail_attributes_dispatch_date")
    const deliveryTimeField = document.getElementById("invoice_delivery_detail_attributes_dispatch_time")
    if (status == "open") {
      if (deliveryDateField.value == "") { deliveryDateField.value = this.currentDateAndTime().date }
      if (deliveryTimeField.value == "") { deliveryTimeField.value = this.currentDateAndTime().time }
    } else {
      deliveryDateField.value = ""
      deliveryTimeField.value = ""
    }
  }

  currentDateAndTime() {
    const now = new Date()
    const hour = now.getHours() + 1
    const time = `${hour}:00`
    return {
      date: now.toLocaleDateString(),
      time: time
    }
  }

}
