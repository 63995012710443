import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.checkUserEmailChange()
  }

  checkUserEmailChange() {
    let user_email = document.querySelector("#user_email")
    let email_confirmation_container = document.querySelector('.email-confirmation-container')
    let sign_in_with_google_btn = document.querySelector('.sign-in-with-google-button')
    if (user_email.value.trim() !== user_email.dataset.emailValue) {
      console.log('email has changed')
      email_confirmation_container.classList.remove('d-none')
      if (sign_in_with_google_btn) {
        sign_in_with_google_btn.classList.remove('d-none')
        document.querySelector('button[type=submit]').classList.add('d-none')
      } else {
        document.querySelector('button[type=submit]').value = 'update-email'
      }
    } else {
      email_confirmation_container.classList.add('d-none')
      if (sign_in_with_google_btn) {
        sign_in_with_google_btn.classList.add('d-none')
      }
      document.querySelector('button[type=submit]').classList.remove('d-none')
      document.querySelector('button[type=submit]').value = 'update'
      console.log('email hasnt changed')
    }
  }
}
